import React, {useState, useEffect} from 'react';
import {withRouter} from "react-router-dom";
import {Row, Col, Input} from 'antd';
import {useSelector} from "react-redux";
import Chat from './Chat';

function LandingPage(props) {
    console.log('Hw start ======================');
    let user = useSelector((state) => state.user);
    const UserInfo = user.userData;     // UserInfo={token, user_id}
    //console.log('UserInfo =', UserInfo);
    // const [HwState, setHwState] = useState(initialList);

    return (
        <div>
            <Row
                style={{
                    padding: "20px",
                    width: "95%",
                    height: "80vh",
                }}
            >
                <Col span={19}>
                    <Chat UserInfo={UserInfo}/>
                </Col>
            </Row>
        </div>
    )
}

export default withRouter(LandingPage)
