import { withRouter } from "react-router-dom";
import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Input, Spin, Tooltip} from 'antd';
import { useSelector, useDispatch } from "react-redux";
import {sleep} from "../../utils/utilfunc"
import axios from "axios";
import Swal from 'sweetalert2';
import MyAudioRecorder from "../../utils/MyAudioRecorder";
import {USER_SERVER} from '../../../config'
import {removeCookie} from "../../../cookie/cookie";

function Chat(props) {
    let user = useSelector((state) => state.user);
    const UserInfo = user.userData;     // UserInfo={token, user_id}
    // const UserInfo = {token:'123456', user_id:'pocblue'};
    
    const [HistoryList, setHistoryList] = useState([]);
    const [WaitAnswer, setWaitAnswer] = useState(false);
    const [Question, setQuestion] = useState('');

    useEffect(() => {
        get_chat_history();
    }, [UserInfo]);

    
    async function get_chat_history() {
        if (!UserInfo?.token)
            return;

        const res = await axios.post(`${USER_SERVER}/get_chat_history`, {user_id:UserInfo.user_id, token:UserInfo.token});
        console.log('get_chat_history--------->', res);
        if (res.data?.success) {
            setHistoryList(res.data.data);
        } else {
            show_error(res);
        }
    }

    // error 나면 에러 정보를 디스플레이
    function show_error(res) {
        const tdata = res.data.data;
        if (typeof tdata ==='object' && 'title' in tdata){
            Swal.fire({
                title: res.data.data.title,
                text: res.data.data.description,
                icon: 'info',
                confirmButtonText: '확인'
              });
            if (tdata.type=='tokenExpired'){
                removeCookie('w_auth');
                props.history.push("/login");    
            }
        } else {
            Swal.fire({
                title: 'Error',
                text: res.data.data,
                icon: 'error',
                confirmButtonText: '확인'
              });
        }
    }

    async function onClickSend(){
        console.log('onClickSend');
        await send_question(Question);
    }

    async function send_question(question){
        setWaitAnswer(true);
        const currentDate = new Date();
        const dataToCommit = {
            token:UserInfo.token, 
            user_id:UserInfo.user_id,
            question:question.trim(),
            tts: false
        }
        
        const res = await axios.post(`${USER_SERVER}/get_answer`, dataToCommit);
        setWaitAnswer(false);
        console.log('get_answer--------->', res);
        if (res.data?.success) {
            await process_answer(res.data.data);
        } else {        
            show_error(res);
        }
    }

    async function process_answer(data) {
        const question = data.question;
        const answer = data.answer;
        const control_object = data.control;
        const user_msg = {speaker:'h', message:question};
        const assistant_msg = {speaker:'c', message:answer};
        // const role = data?.role;
        setHistoryList([...HistoryList, user_msg, assistant_msg]);

        // role이 clova이면 처리
        // if (role=='clova') {
        //     control_object.clova = 'true';
        // } else {
        //     control_object.clova = 'false';
        // }
        

        if (data?.function) {
            const tstr = JSON.stringify(data.function);
            const newstr = tstr.replace(/["\\"]/g, '');
            if (newstr!=='{}'){                 // empty 이면 추가 안함
                assistant_msg.gptfunc = newstr;
            }
        }
    }

    async function onClickReset() {
        setWaitAnswer(true);
        const res = await axios.post(`${USER_SERVER}/reset_history`, {token:UserInfo.token, user_id:UserInfo.user_id});
        console.log('reset_history--------->', res);
        if (res.data?.success) {
            await get_chat_history();
        } else {
            alert('Error:'+res.data.data);
        }
        setWaitAnswer(false);
    }


    const render_histlist = HistoryList  && HistoryList.map(function(hist, index){
        const prompt = hist.speaker==='h'? 'User:  ':'Assistant:  ';
        const func_msg = (hist?.gptfunc)? '  (' + hist.gptfunc + ')' : '';
        return (
            <div key={index}>
                <Row>
                    <Col>
                        {prompt}{hist.message}{func_msg}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {hist.speaker==='c' && <br/>}
                    </Col>
                </Row>
            </div>
        );
		});


    return (
    <div>
        <Row style={{marginTop:'20px'}}>
            <Col>
                {render_histlist}                
            </Col>
        </Row>
        <Row justify="center" align='middle'>
            Input:
            <Input disabled={WaitAnswer} value={Question} onChange={(e)=>setQuestion(e.currentTarget.value)} onPressEnter={onClickSend} style={{width:'85%', margin:10}}/>
        </Row>

        <Row justify="center" align='middle'>
            <Col>
            {
                WaitAnswer? <Spin/> :
                            <Button disabled={WaitAnswer} type='primary' onClick={onClickSend}  style={{margin:10}}>
                                Send Text
                            </Button>
            }
            </Col>
            <Col>
                <Button disabled={WaitAnswer} type='primary' onClick={onClickReset} style={{marginLeft:30}}>Reset and Restart</Button>
            </Col>
        </Row>
    </div>
)
}

export default withRouter(Chat)
