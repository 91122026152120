import axios from 'axios';
import { LOGIN_USER, REGISTER_USER, AUTH_USER, LOGOUT_USER } from './types';
import {USER_SERVER} from '../config'

export function registerUser(dataToSubmit) {
    
    const request = axios.post(`${USER_SERVER}/register`, dataToSubmit).then(response => response.data);

    return {
        type: REGISTER_USER,
        payload: request
    }
}

export function loginUserId(dataToSubmit) {

    const request = axios.post(`${USER_SERVER}/web/login_user_id`, dataToSubmit).then(response => response.data);

    return {
        type: LOGIN_USER,
        payload: request
    }
}

export function loginGoogleUser(dataToSubmit) {

    const request = axios.post(`${USER_SERVER}/web/logingoogle`, dataToSubmit).then(response => response.data);

    return {
        type: LOGIN_USER,
        payload: request
    }
}

export function auth() {

    // const request = axios.get(`${USER_SERVER}/auth`).then(response => response.data);
    const request = axios.get(`${USER_SERVER}/web/auth`).then(response => response.data);

    return {
        type: AUTH_USER,
        payload: request
    }
}

// export function logoutUser() {
//     const request = axios.get(`${USER_SERVER}/web/logoutgoogle`).then(response => response.data);
//     return {
//         type: LOGOUT_USER,
//         payload: request
//     }
// }

